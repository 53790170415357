import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'

import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

Vue.prototype.$dialog.confirmation = {
  deletion (message) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$dialog.confirm({
        title: i18n.t('commons.attention').toString(),
        icon: false,
        type: 'error',
        actions: [
          i18n.t('commons.cancel'),
          { color: 'error', text: i18n.t('commons.delete') }
        ],
        text: message
      })
        .then((val) => {
          if (val === i18n.t('commons.delete')) resolve()
          else if (reject) reject(new Error())
        })
    })
  }
}

Vue.prototype.$showError = function (message) {
  Vue.prototype.$dialog.error({
    title: i18n.t('commons.attention'),
    icon: false,
    actions: { text: i18n.t('commons.close') },
    text: message
  })
}
