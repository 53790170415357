<template src="./ClientCustomerRegistration.html"/>
<style scoped src="./ClientCustomerRegistration.scss" lang="scss" />

<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import FormCard from '@/components/FormCard/FormCard'

setInteractionMode('eager')

export default {
  name: 'FoodyTekClientRegistration',
  components: {
    ValidationProvider,
    ValidationObserver,
    FormCard
  },
  data () {
    return {
      accountInfo: [
        { name: 'Name', value: this.$store.getters.name + ' ' + this.$store.getters.surname },
        { name: 'Email', value: this.$store.getters.email }
      ],

      validCountries: [
        { text: 'Italia', value: 'it' },
        { text: 'United States', value: 'us' }
      ],

      businessInformation: {
        address: {
          city: null,
          country: null,
          line1: null,
          line2: null,
          postalCode: null,
          state: null
        },
        businessName: null,
        certifiedEmail: null,
        codiceDestinatario: null,
        taxId: null,
        vatId: null
      },

      loading: false
    }
  },
  methods: {
    async submitBusinessInformation () {
      try {
        const valid = await this.$refs.businessInformationObserver.validate()
        if (valid) {
          await this.register()
        }
      } catch (e) {
      }
    },
    async register () {
      this.loading = true
      try {
        await this.$http.auth.post(
          '/client-customers',
          this.businessInformation
        )

        await this.waitMillis(100)
        this.$store.dispatch('renewToken', {
          token: this.$store.getters.token
        })

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async waitMillis(millis) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, millis)
      })
    },
    logoutUser () {
      this.$store.commit('logoutUser')
    },
    copyHeadquarterAddress () {
      Object.assign(this.businessInformation.shipmentAddress, this.businessInformation.address)
    }
  }
}
</script>
