var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Registration"}},[_c('LoadingModal',{attrs:{"show":_vm.loading,"text":"Loading..."}}),_c('v-container',[_c('h3',{staticClass:"text-h3 mt-4"},[_vm._v(_vm._s(_vm.$t('FoodyTekClientRegistration.FoodyTekClientInformation')))]),_c('p',{staticClass:"text-h6 my-2 font-weight-regular",staticStyle:{"color":"#5f6368"}},[_vm._v(_vm._s(_vm.$t('FoodyTekClientRegistration.comeBackHint')))]),_c('form-card',{staticClass:"text-left",attrs:{"title":_vm.$t('registration.accountInfo')}},[_vm._l((_vm.accountInfo),function(row,index){return _c('v-row',{key:index},[_c('v-col',{staticClass:"text-overline",attrs:{"cols":"3","lg":"2"}},[_vm._v(_vm._s(row.name))]),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"9","lg":"10"}},[_vm._v(_vm._s(row.value))])],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('commons.isNotYou')))]),_c('a',{staticClass:"ml-2",on:{"click":_vm.logoutUser}},[_vm._v(_vm._s(_vm.$t('commons.loginWithOtherAccount')))])])],1)],2),_c('validation-observer',{ref:"businessInformationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-card',{attrs:{"title":_vm.$t('FoodyTekClientRegistration.businessInformation')}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.businessName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.businessName'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.businessName),callback:function ($$v) {_vm.$set(_vm.businessInformation, "businessName", $$v)},expression:"businessInformation.businessName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.vatId'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.vatId'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.vatId),callback:function ($$v) {_vm.$set(_vm.businessInformation, "vatId", $$v)},expression:"businessInformation.vatId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.taxId'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.taxId'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.taxId),callback:function ($$v) {_vm.$set(_vm.businessInformation, "taxId", $$v)},expression:"businessInformation.taxId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.certifiedEmail'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.certifiedEmail'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.certifiedEmail),callback:function ($$v) {_vm.$set(_vm.businessInformation, "certifiedEmail", $$v)},expression:"businessInformation.certifiedEmail"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Codice univoco","rules":"required|length:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":"Codice destinatario","error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.codiceDestinatario),callback:function ($$v) {_vm.$set(_vm.businessInformation, "codiceDestinatario", $$v)},expression:"businessInformation.codiceDestinatario"}})]}}],null,true)})],1)],1)],1),_c('form-card',{attrs:{"title":_vm.$t('FoodyTekClientRegistration.headquarterAddress')}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.city'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.city'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.city),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "city", $$v)},expression:"businessInformation.address.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.state'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.state),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "state", $$v)},expression:"businessInformation.address.state"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.address1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.address1'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.line1),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "line1", $$v)},expression:"businessInformation.address.line1"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.address2'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.address2'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.line2),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "line2", $$v)},expression:"businessInformation.address.line2"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.postalCode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.postalCode'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.postalCode),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "postalCode", $$v)},expression:"businessInformation.address.postalCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.country'),"items":_vm.validCountries,"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.country),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "country", $$v)},expression:"businessInformation.address.country"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"text-right mb-10 mx-auto card-container"},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('FoodyTekClientRegistration.completeRegistration'))},on:{"click":_vm.submitBusinessInformation}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }