var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Registration"}},[_c('LoadingModal',{attrs:{"show":_vm.loading,"text":_vm.loadingModalText}}),_c('v-container',[_c('h3',{staticClass:"text-h3 mt-4"},[_vm._v(" "+_vm._s(_vm.isAuthenticated ? _vm.$t('registration.acceptInvite') : _vm.$t('registration.createAccount'))+" ")]),_c('p',{staticClass:"text-h6 my-2 font-weight-regular",staticStyle:{"color":"#5f6368"}},[_vm._v(_vm._s(_vm.$t('registration.credentialsLabel')))]),_c('validation-observer',{ref:"credentialsObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-card',{attrs:{"title":_vm.$t('registration.accountInfo')}},[(_vm.isAuthenticated)?_c('div',{staticClass:"text-left mb-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":_vm.$t('commons.data.phoneNumber'),"outlined":"","required":""},model:{value:(_vm.acceptInviteData.mobilePhoneNumber),callback:function ($$v) {_vm.$set(_vm.acceptInviteData, "mobilePhoneNumber", $$v)},expression:"acceptInviteData.mobilePhoneNumber"}})]}}],null,true)})],1),_c('p',[_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.$t('registration.alreadyLoggedWithAccount', { email: _vm.$store.getters.email }))}}),_c('a',{staticClass:"text-decoration-underline",on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t('registration.noLogout')))])])],1):_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"label":_vm.$t('commons.data.name'),"error-messages":errors,"outlined":"","required":""},model:{value:(_vm.credentials.name),callback:function ($$v) {_vm.$set(_vm.credentials, "name", $$v)},expression:"credentials.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('commons.data.surname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":_vm.$t('commons.data.surname'),"outlined":"","required":""},model:{value:(_vm.credentials.surname),callback:function ($$v) {_vm.$set(_vm.credentials, "surname", $$v)},expression:"credentials.surname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":"Email","outlined":"","required":""},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":"Password","type":"password","outlined":"","required":""},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":_vm.$t('commons.data.phoneNumber'),"outlined":"","required":""},model:{value:(_vm.credentials.mobilePhoneNumber),callback:function ($$v) {_vm.$set(_vm.credentials, "mobilePhoneNumber", $$v)},expression:"credentials.mobilePhoneNumber"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-left"},[_c('p',[_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.$t('registration.alreadyRegistered'))}}),_c('a',{staticClass:"text-decoration-underline",on:{"click":_vm.goToLogin}},[_vm._v(_vm._s(_vm.$t('registration.login')))])])])],1),_c('div',{staticClass:"text-left"},[_c('p',[_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.$t('registration.onceInviteAccepted'))}}),_c('a',{attrs:{"href":"https://login.foodytek.com","target":"_blank"}},[_vm._v("https://login.foodytek.com")])])]),_c('div',{staticClass:"text-right mb-3"},[_c('v-btn',{attrs:{"color":"primary","type":"submit","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.isAuthenticated ? _vm.$t('registration.acceptInvite') : _vm.$t('registration.register'))},on:{"click":_vm.submit}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }