<template src="./FormCard.html"/>
<style scoped src="./FormCard.scss" lang="scss"/>

<script>
export default {
  name: 'FormCard',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
