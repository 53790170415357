<template src="./NavBar.html"/>

<script>
export default {
  name: 'NavBar',
  data () {
    return {
      languages: [
        { title: 'Italiano', value: 'it' },
        { title: 'English', value: 'en' }
      ]
    }
  },
  methods: {
    filter (lang) {
      if (lang === 'en') return 'gb'
      return lang
    },
    languageFromValue (lang) {
      let title
      this.languages.forEach((el) => {
        if (el.value.includes(lang)) {
          title = el.title
        }
      })
      return title
    },
    changeLang (lang) {
      this.$i18n.locale = lang
      this.$store.commit('changeLang', lang)
    }
  }
}
</script>
