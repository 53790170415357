import { configure, extend } from 'vee-validate'
import i18n from '@/plugins/i18n'

import { between, email, length, max, min, required } from 'vee-validate/dist/rules'

configure({
  defaultMessage: (field, values) => {
    return i18n.t(`validation.${values._rule_}`, values)
  }
})

extend('required', required)
extend('email', email)
extend('min', min)
extend('length', length)
extend('between', between)
extend('max', max)
