<template>
  <div class="mt-5">
<!--    <v-container v-if="$env.isLocalhost">-->
<!--      <div>Logged in user role: <b>{{$store.getters.role}}</b></div>-->
<!--      <v-text-field-->
<!--          label="Redirect to"-->
<!--          v-model="path"-->
<!--          outlined-->
<!--      />-->
<!--      <v-btn color="success" @click="handleLocalhostRedirect">-->
<!--        Redirect-->
<!--      </v-btn>-->
<!--    </v-container>-->
    <div>Redirecting...</div>
  </div>
</template>

<script>
export default {
  name: 'Redirect',
  data () {
    return {
      path: 'http://localhost:8080'
    }
  },
  mounted () {
    // if (this.$env.isLocalhost) { return }

    this.handleRedirect()
  },
  methods: {
    handleLocalhostRedirect () {
      // if (!this.path.endsWith('/')) { this.path += '/' }

      this.redirectTo(this.path)
    },
    redirectTo (basePath, customPath) {
      window.location = basePath + `/login?token=${encodeURIComponent(this.$store.getters.token)}` +
          (customPath
            ? `&to=${encodeURIComponent(customPath)}` : '')
    },
    handleRedirect () {
      const q = this.$route.query
      if (q.continueRegistrationToken) {
        this.$router.push('/register/' + q.continueRegistrationToken)
        return
      }

      const to = q.to

      let requiredUrl = null
      let requiredPath = null
      if (to) {
        if (to.startsWith('https')) {
          const regResult = !this.$env.isLocalhost && to.match(RegExp('(https://[a-zA-Z.]*.foodytek.com)(/[a-zA-Z]*)'))
          requiredUrl = regResult[1]
          requiredPath = regResult[2]
        } else requiredPath = ''
      } else requiredPath = ''

      if (!requiredUrl) requiredUrl = this.getAllowedUrl()

      if (this.isAllowed(requiredUrl)) this.redirectTo(requiredUrl, requiredPath)
      else this.redirectTo(this.getAllowedUrl())
    },
    getAllowedUrl () {
      if (this.$env.isLocalhost) return 'http://localhost:8081'

      return `https://dashboard.${this.$env.domainPostfix}`
    },
    isAllowed (requestedUrl) {
      if (this.$env.isLocalhost) return true

      const regExUrl = `^https://docs.${this.$env.domainPostfix}`
      const isDocs = RegExp(regExUrl).test(requestedUrl)

      const isAdmin = this.$store.getters.role === 'admin'

      return isAdmin || !isDocs
    }
  }
}
</script>
