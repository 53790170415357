<template src="./Login.html"/>

<script>
export default {
  name: 'Login',
  data () {
    return {
      credentials: {
        email: '',
        password: ''
      },
      loginButtonDisabled: false,
      loading: false,

      showPassword: false
    }
  },
  methods: {
    login () {
      if (this.loginButtonDisabled) { return }

      this.loading = true
      this.loginButtonDisabled = true

      this.$store.dispatch('login', {
        authCredentials: this.credentials,
        redirectTo: this.$route.query.to,
        continueRegistrationToken: this.$route.query.continueRegistrationToken
      })
      this.loading = false
      this.loginButtonDisabled = false
    }
  }
}
</script>
