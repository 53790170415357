import axios from 'axios'
import Vue from 'vue'
import i18n from '@/plugins/i18n'
import { store } from '@/store/store'
import apiDomainPostfix from '@/api-domain-postfix.json'

const authBaseUrl = '/api/v1'
const deviceBaseUrl = '/api/v1'

// ---------------- Use localhost setting ----------------
const useApiLocalhostUrl = true

const isLocalhost = location.hostname === 'localhost'
const domainPostfix = location.hostname.split('.').slice(1).join('.')
const isDev = domainPostfix !== apiDomainPostfix.prod

Vue.prototype.$env = {
  isLocalhost,
  isDev,
  domainPostfix
}

const authUrl = (!isLocalhost ? 'https://auth.' + domainPostfix
        : useApiLocalhostUrl
            ? 'http://localhost:8000'
            : 'https://auth.' + apiDomainPostfix.dev
) + authBaseUrl

const deviceUrl = (!isLocalhost ? 'https://device.' + domainPostfix
        : useApiLocalhostUrl
            ? 'http://localhost:8001'
            : 'https://device.' + apiDomainPostfix.dev
) + deviceBaseUrl

const headers = store.getters.token ? {
  Authorization: 'Bearer ' + store.getters.token
} : {}

const auth = axios.create({
  baseURL: authUrl,
  headers
})
const device = axios.create({
  baseURL: deviceUrl,
  headers
})

const noCodeCallback = () => {
}

function showError (text, callback) {
  Vue.prototype.$dialog.confirm({
    title: i18n.t('commons.attention'),
    text,
    icon: false,
    type: 'error',
    actions: [i18n.t('commons.close')]
  })
    .then(callback || noCodeCallback)
    .catch(callback || noCodeCallback)
}

let errorOpen = false

const interceptorFulfilled = response => response
const interceptorRejected = error => {
  if (!error.response) {
    if (!errorOpen) {
      errorOpen = true
      showError(i18n.t('commons.noServerConnection'), () => {
        errorOpen = false
      })
    }
  } else if (error.response.status === 401 && store.getters.isAuthenticated) {
    if (!errorOpen) {
      errorOpen = true
      showError(i18n.t('commons.sessionExpired'), () => {
        errorOpen = false
      })
      store.commit('logoutUser')
    }
  } else if (error.response.status === 403) {
    if (!errorOpen) {
      errorOpen = true
      // showError(i18n.t('commons.sessionExpired'), () => { // TODO: Add language to forbidden http error
      showError('To be translated! Permission denied to perform this action', () => {
        errorOpen = false
      })
    }
  } else {
    showError(error.response.data.message || i18n.t('commons.genericError'))
  }

  return Promise.reject(error)
}
auth.interceptors.response.use(interceptorFulfilled, interceptorRejected)
device.interceptors.response.use(interceptorFulfilled, interceptorRejected)

export default {
  auth,
  device
}
