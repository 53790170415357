import i18n from '@/plugins/i18n'

const state = {
  language: null
}

const getters = {
  language: (state) => {
    return state.language
  }
}

const mutations = {
  changeLang (state, lang) {
    state.language = lang
    i18n.locale = lang
    document.title = i18n.t('commons.appTitle').toString()
  }
}

const actions = {
}

export default {
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
